<template>
    <div id="mapContainer" style="width:100%; height:100%; position: absolute;"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    name: 'MapComponent',
    data() {
        return {
            btndisabled: true,
            loadshow: false,
            loadtext: '提交中',
            showtop: true,
            starshow: true,
            endshow: true,
            userinfo: {},
            cphList: [],
            sjmcList: [],
            cphid: '',
            cphmc: '',
            sjmcid: '',
            sjmc: '',
            startime: '',
            endtime: '',
            sigInfo: {},
            map: "",
            lineArr: [],
            lineArrlast: [],
            lineArrPre: [],
            marker: '',
            k: 0,
            dataForm: {
                kqName: undefined,
                kqLocation: undefined,
                kqLongitude: undefined,
                kqLatitude: undefined,
                kqWorkUnit: undefined,
                cronkqAccredit: [],
                kqValidCardRange: undefined,
            },
            auto: null,
            placeSearch: null,
            lnglat: [],
            markers: [],
            position: {},
            longitudeLatitude: [119.685439,31.338547]
        }
    },
    mounted() {
        AMapLoader.reset()
        // this.initMap()
    },
    methods: {
        // 地图初始化
        initMap (row) {
            // if (!row) {
                let centerLen = [119.685439,31.338547];
                AMapLoader.load({
                    // 替换为你的高德地图API Key
                    key: 'ec9cb54feced4a6f72021702ee8ca907',
                    // version: '2.0',      
                    plugins: ['AMap.MoveAnimation','AMap.Geolocation','AMap.MouseTool'],
                })
                .then((AMap) => {
                this.map = new AMap.Map("mapContainer", {
                    // 设置地图容器id
                    zoom: 15, // 初始化地图级别
                    center: centerLen, //中心点坐标
                  layers: [
                    // 卫星
                    new AMap.TileLayer.Satellite(),
                    // 路网
                    new AMap.TileLayer.RoadNet()
                  ],
                    resizeEnable: true,
                });
                this.map.setMapStyle('amap://styles/dark');
                this.setMarker(centerLen)
                // 关键字查询
                // this.searchMap();
                // 监听鼠标点击事件
                this.map.on("click", this.clickMapHandler);
                })
                .catch((e) => {
                    //console.log('e====>',e);
                });
        },
        // 点击地图事件获取经纬度，并添加标记
        clickMapHandler (e) {
            this.dataForm.kqLongitude = e.lnglat.getLng();
            this.dataForm.kqLatitude = e.lnglat.getLat();
            this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
            this.setMarker(this.lnglat);
            const that = this
            this.longitudeLatitude = []
            this.position = {
                // 经度
                longitude: e.lnglat.getLng(),
                // 纬度
                latitude: e.lnglat.getLat(),
                address: that.address,
            };
            this.longitudeLatitude.push(this.position.latitude)
            this.longitudeLatitude.push(this.position.longitude)
        },
        //  添加标记
        setMarker (lnglat) {
            this.removeMarker();
            let marker = new AMap.Marker({
                position: lnglat,
            });
            marker.setMap(this.map);
            this.markers.push(marker);
        },
        // 删除之前后的标记点
        removeMarker () {
            if (this.markers) {
                this.map.remove(this.markers);
            }
        },
    }
};
</script>